<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >
      <b-card v-if='!isDataLoaded'>
        <div class='text-center text-success my-2'>
          <b-spinner class='align-middle' />
        </div>
      </b-card>

      <b-form
        v-else
        novalidate
        @submit.prevent='onSubmit'
      >
        <b-card>
          <b-row>
            <b-col
              cols='12'
              md='6'
              v-if='form.legal_name'
            >
              <ValidationProvider
                v-slot='{ errors }'
                vid='legal_name'
                :name="$t('labels.legal_name')"
              >
                <b-form-group
                  :label="$t('labels.legal_name')"
                >
                  <b-form-input
                    :value='form.legal_name'
                    :placeholder="$t('labels.legal_name')"
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols='12'
              md='6'
            >
              <ValidationProvider
                v-slot='{ errors }'
                vid='name'
                :name="$t('labels.name')"
              >
                <b-form-group
                  :label="$t('labels.name')"
                >
                  <b-form-input
                    v-model='form.name'
                    :placeholder="$t('labels.name')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <ValidationProvider-->
            <!--                v-slot='{ errors }'-->
            <!--                vid='email'-->
            <!--                :name="$t('labels.email')"-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('labels.email')"-->
            <!--                >-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.email'-->
            <!--                    :placeholder="$t('labels.email')"-->
            <!--                    type='email'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </ValidationProvider>-->
            <!--            </b-col>-->
            <b-col
              cols='12'
              md='6'
            >
              <ValidationProvider
                v-slot='{ errors }'
                vid='mobile'
                :name="$t('labels.mobile')"
                :rules="'required'"
              >

                <!--                :rules='{-->
                <!--                required: true,-->
                <!--                numeric: true,-->
                <!--                }'-->
                <!--                regex: /^0[0-9]{8,11}$/,-->

                <b-form-group
                  :label="$t('labels.mobile')"
                >
                  <b-form-input
                    style='direction: ltr !important;text-align: right !important;'
                    v-model='form.mobile'
                    :placeholder="$t('labels.mobile')"
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <ValidationProvider-->
            <!--                v-slot='{ errors }'-->
            <!--                vid='password'-->
            <!--                :name="$t('labels.password')"-->
            <!--                :rules="isEdit ? '':'required'"-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('labels.password')"-->
            <!--                >-->
            <!--                  <b-input-group-->
            <!--                    class='input-group-merge'-->
            <!--                    :class="errors.length > 0 ? 'is-invalid':null"-->
            <!--                  >-->
            <!--                    <b-form-input-->
            <!--                      id='password'-->
            <!--                      v-model='form.password'-->
            <!--                      :state='errors.length > 0 ? false:null'-->
            <!--                      class='form-control-merge'-->
            <!--                      :type='passwordFieldType'-->
            <!--                      :placeholder="$t('labels.password')"-->
            <!--                    />-->
            <!--                    <b-input-group-append is-text>-->
            <!--                      <feather-icon-->
            <!--                        class='cursor-pointer'-->
            <!--                        :icon='passwordToggleIcon'-->
            <!--                        @click='togglePasswordVisibility'-->
            <!--                      />-->
            <!--                    </b-input-group-append>-->
            <!--                  </b-input-group>-->
            <!--                </b-form-group>-->
            <!--                <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--              </ValidationProvider>-->
            <!--            </b-col>-->
            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <ValidationProvider-->
            <!--                v-slot='{ errors }'-->
            <!--                vid='confirm_password'-->
            <!--                :name="$t('labels.confirm_password')"-->
            <!--                :rules="isEdit ? '':'required'"-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('labels.confirm_password')"-->
            <!--                >-->
            <!--                  <b-input-group-->
            <!--                    class='input-group-merge'-->
            <!--                    :class="errors.length > 0 ? 'is-invalid':null"-->
            <!--                  >-->
            <!--                    <b-form-input-->
            <!--                      id='password_confirmation'-->
            <!--                      v-model='form.password_confirmation'-->
            <!--                      :state='errors.length > 0 ? false:null'-->
            <!--                      class='form-control-merge'-->
            <!--                      :type='passwordFieldTypeConfirm'-->
            <!--                      :placeholder="$t('labels.confirm_password')"-->
            <!--                    />-->
            <!--                    <b-input-group-append is-text>-->
            <!--                      <feather-icon-->
            <!--                        class='cursor-pointer'-->
            <!--                        :icon='passwordToggleIconConfirm'-->
            <!--                        @click="passwordFieldTypeConfirm = (passwordFieldTypeConfirm === 'text') ? 'password' : 'text'"-->
            <!--                      />-->
            <!--                    </b-input-group-append>-->
            <!--                  </b-input-group>-->
            <!--                </b-form-group>-->
            <!--                <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--              </ValidationProvider>-->
            <!--            </b-col>-->
          </b-row>
          <b-row v-if='isEdit'>
            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <DatePicker-->
            <!--                v-model='form.blocking_start_date'-->
            <!--                :label="$t('blocking_start_date')"-->
            <!--              />-->
            <!--            </b-col>-->
            <!--            <b-col-->
            <!--              cols='12'-->
            <!--              md='6'-->
            <!--            >-->
            <!--              <DatePicker-->
            <!--                :min='min'-->
            <!--                :max='max'-->
            <!--                v-model='form.blocking_end_date'-->
            <!--                :label="$t('blocking_end_date')"-->
            <!--              />-->
            <!--            </b-col>-->
          </b-row>
          <b-row>
            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col
              cols='12'
              md='6'
             >
              <label>{{ $t('fal_status') }}</label>
              <v-select
                v-model='form.fal_status'
                :dir='$store.state.appConfig.layout.direction'
                :options='falStatuses'
                :reduce='item => item.id'
                label='name'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>

            <b-col cols='12' class='my-2'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='fal_document'
                :name="$t('labels.fal_document')"
              >
                <ImagePreview
                  v-model='form.fal_document'
                  :label="$t('labels.fal_document')"
                  :isImage='false'
                  accept-type='.pdf,.png,.doc'
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='isEdit && fal_document'
            >
              <a href='#' @click.prevent='downloadFile'
                 class='btn btn-primary w-100 main-btn-link mt-2'>{{ $t('download_fal_file') }}</a>

            </b-col>
            <b-col
              cols='12'
              md='3'
              class='mt-2'
              v-if='form.fal_status == 2'
              rules='required'
            >

              <validation-provider
                v-slot='{ errors }'
                vid='fal_expiration_date_at'
                :name="$t('fal_expiration_date_at')"
                rules='required'
              >
                <label>{{ $t('fal_expiration_date_at') }}</label>
                <DatePicker
                  :reset='true'
                  v-model='form.fal_expiration_date_at'
                />

                <small class='text-danger'>{{ errors[0] }}</small>

              </validation-provider>
            </b-col>


            <b-col cols='4' class='mt-2'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='true'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>
            <b-col
              v-if='isEdit'
              cols='4'
            >
              <b-card-text class='mb-0 mt-2'>
                {{ $t('labels.blocking_status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.blocking_status'
                checked='true'
                value='true'
                unchecked-value='inactive'
                name='check-button'
                switch
              />
            </b-col>

            <b-col
              cols='12 text-right'
              class='mt-4'
            >
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
                @click='reset()'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formMixin from '@/mixins/formMixin'

export default {
  // mixins: [togglePasswordVisibility, formMixin],

  mixins: [formMixin],
  data() {
    return {
      image: null,
      fal_document: null,

      // passwordFieldTypeConfirm: 'password',
      min: null,
      max: null,
      form: {
        legal_name: null,
        name: null,
        email: null,
        mobile: null,
        image: null,
        fal_document: null,
        fal_expiration_date_at: null,
        // password: null,
        status: 1,
        fal_status: 0,
        // password_confirmation: null,
        // blocking_start_date: null,
        // blocking_end_date: null,
        blocking_status: 'false',
      },
      isDataLoaded: false,
    }
  },
  computed: {
    falStatuses() {
      return [
        {
          id: 0,
          name: this.$t('not_licensed'),
        },
        {
          id: 1,
          name: this.$t('under_studying'),
        },
        {
          id: 2,
          name: this.$t('licensed'),
        },
      ]
    },

    // passwordToggleIcon() {
    //   return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },
    // passwordToggleIconConfirm() {
    //   return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    // 'form.blocking_start_date': function(val) {
    //   if (val) {
    //     const maxDate = new Date(val)
    //     maxDate.setFullYear(maxDate.getFullYear() + 1)
    //     maxDate.setMonth(maxDate.getMonth())
    //     this.max = maxDate
    //     this.min = val
    //   }
    // },
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },

    'fal_document': {
      handler(val) {
        if (val) {
          this.form.fal_document = val
        } else {
          this.form.fal_document = 'delete'
        }
      },
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    async downloadFile() {

      try {
        const response = await fetch(this.fal_document)
        const blob = await response.blob()

        // Extract filename from URL
        const filename = 'fal' + '-' + this.generateFileName(5)

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      } catch (error) {
        console.error('Error downloading the file:', error)
      }
    },
    generateFileName(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let result = ''
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    reset() {
      this.image = null
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            ...this.form,
            // password: data.password ? data.password : null,
            // password_confirmation: data.password_confirmation ? data.password : null,
            name: data.name,
            legal_name: data.legal_name,
            email: data.email,
            mobile: data.mobile,
            status: data.status,
            fal_status: data.fal_status,
            fal_expiration_date_at: data.fal_expiration_date_at,
            // blocking_start_date: data.blocking_start_date,
            // blocking_end_date: data.blocking_end_date,
            blocking_status: data.blocking_status,
          }
          this.image = data.image_path
          this.fal_document = data.fal_document_path
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File) && $form.image != 'delete') {
          delete $form.image
        }
        if (!($form.fal_document instanceof File)) {
          delete $form.fal_document
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
<style>

</style>
