var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-card',[_c('b-row',[(_vm.form.legal_name)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"legal_name","name":_vm.$t('labels.legal_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.legal_name')}},[_c('b-form-input',{attrs:{"value":_vm.form.legal_name,"placeholder":_vm.$t('labels.legal_name'),"disabled":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1264008845)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('labels.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.name')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"mobile","name":_vm.$t('labels.mobile'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.mobile')}},[_c('b-form-input',{staticStyle:{"direction":"ltr !important","text-align":"right !important"},attrs:{"placeholder":_vm.$t('labels.mobile'),"type":"text"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.isEdit)?_c('b-row'):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"image","name":_vm.$t('labels.image')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.image')},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('fal_status')))]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"options":_vm.falStatuses,"reduce":function (item) { return item.id; },"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('No options')))]},proxy:true}]),model:{value:(_vm.form.fal_status),callback:function ($$v) {_vm.$set(_vm.form, "fal_status", $$v)},expression:"form.fal_status"}})],1),_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"fal_document","name":_vm.$t('labels.fal_document')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.fal_document'),"isImage":false,"accept-type":".pdf,.png,.doc"},model:{value:(_vm.form.fal_document),callback:function ($$v) {_vm.$set(_vm.form, "fal_document", $$v)},expression:"form.fal_document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('hr',{staticClass:"my-1"})],1),(_vm.isEdit && _vm.fal_document)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('a',{staticClass:"btn btn-primary w-100 main-btn-link mt-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile($event)}}},[_vm._v(_vm._s(_vm.$t('download_fal_file')))])]):_vm._e(),(_vm.form.fal_status == 2)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"3","rules":"required"}},[_c('validation-provider',{attrs:{"vid":"fal_expiration_date_at","name":_vm.$t('fal_expiration_date_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('fal_expiration_date_at')))]),_c('DatePicker',{attrs:{"reset":true},model:{value:(_vm.form.fal_expiration_date_at),callback:function ($$v) {_vm.$set(_vm.form, "fal_expiration_date_at", $$v)},expression:"form.fal_expiration_date_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4117948391)})],1):_vm._e(),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"1","unchecked-value":"0","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),(_vm.isEdit)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-card-text',{staticClass:"mb-0 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('labels.blocking_status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"true","unchecked-value":"inactive","name":"check-button","switch":""},model:{value:(_vm.form.blocking_status),callback:function ($$v) {_vm.$set(_vm.form, "blocking_status", $$v)},expression:"form.blocking_status"}})],1):_vm._e(),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }